import loadable from '../utils/loadable'

const App = loadable(() => import('@/containers/App'));
const Home = loadable(() => import('@/containers/home'));
const Login = loadable(() => import('@/containers/login'));
const Advertisement = loadable(() => import('../../src/containers/advertisement'));
const Portalgeneral = loadable(() => import('../../src/containers/portalgeneral'));
const Adposition = loadable(() => import('../../src/containers/adposition'));
const SettingPassword = loadable(() => import('../../src/containers/settingPassword'));
const Infomation = loadable(() => import('../../src/containers/infomation'));
const InfomationAdd = loadable(() => import('../../src/containers/infomation/component/addAdvert'));
const InfomationAddNew = loadable(() => import('../../src/containers/infomation/component/addAdvertNew'));
const Portalmenu = loadable(() => import('../../src/containers/portalmenu'));
const UserManagement = loadable(() => import('../../src/containers/userManagement'));
const ErrorPage = loadable(() => import('../../src/components/errorPage/index'));

export default [
	{
		path: '/admin',
		name: 'App',
		component: App,
		children: [
			{
				path: '/admin/home',
				name: 'home',
				component: Home
			},
			{
				path: '/admin/advertisement',
				name: 'advertisement',
				component: Advertisement
			},
			{
				path: '/admin/portalgeneral',
				name: 'portalgeneral',
				component: Portalgeneral
			},
			{
				path: '/admin/infomation',
				name: 'infomation',
				component: Infomation
			},
			{
				path: '/admin/infomationadd',
				name: 'infomationadd',
				component: InfomationAddNew
			},
			{
				path: '/admin/infomationaddnew',
				name: 'infomationaddnew',
				component: InfomationAddNew
			},
			{
				path: '/admin/settingPassword',
				name: 'settingPassword',
				component: SettingPassword
			},
			{
				path: '/admin/adposition',
				name: 'adposition',
				component: Adposition
			},
			{
				path: '/admin/portalmenu',
				name: 'portalmenu',
				component: Portalmenu
			},
			{
				path: '/admin/userManagement',
				name: 'userManagement',
				component: UserManagement
			},
			{
				component: ErrorPage
			}
		]
	},

	//登录页
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		component: Login
	},
	{
		redirect: '/',
		name: 'defaultRoute',
		component: '/login'
	},
]